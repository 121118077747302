<template>
    <AppPaginatedGrid
        ref="grid"
        :service="service"
        :actionItems="actionItems"
        @onRowSelect="onRowSelect"
        permission="configuracoes_feriados"
        :expander="false"
        subtitle="Listagem de Feriados"
        tooltip="Listagem de Feriados"
        nomeTelaDoManual="configuracoes_feriados-list"
    >
        <template #columns>
            <Column field="nome" header="Nome" sortField="nome" :sortable="true"></Column>
            <Column field="dataFeriado" header="Data Feriado" sortField="dataFeriado" :sortable="true">
                <template #body="{ data }">
                    {{ $filters.formatDateOnly(data.dataFeriado) }}
                </template>
            </Column>
            <Column field="abrangencia" header="Abrangência" sortField="abrangencia" :sortable="true"></Column>
            <Column field="cidade.cidade" header="Cidade" sortField="cidade.cidade" :sortable="true">
                <template #body="{ data }">
                    {{ data?.cidade?.cidade ? data?.cidade?.cidade : 'NACIONAL' }}
                </template>
            </Column>
            <Column field="periodo" header="Periodo" sortField="periodo" :sortable="true">
                <template #body="slotProps">
                    {{ renderTipoHorario(slotProps.data?.periodo) }}
                </template>
            </Column>
        </template>
    </AppPaginatedGrid>
    <AppLogAcoesDialog v-if="showLogAcoesDialog" origem="/feriados" :origem_id="record.id" v-model:visible="showLogAcoesDialog" />
</template>
<script>
import BaseService from '@/services/BaseService';
import { getCurrentCompany, getCurrentCustomers } from '@/services/store';
import AppLogAcoesDialog from '../../../components/AppLogAcoesDialog.vue';

export default {
    components: {
        AppLogAcoesDialog
    },
    emits: ['onEdit'],
    data() {
        return {
            tenantCustomers: [],
            service: null,
            showLogAcoesDialog: false,
            actionItems: [
                {
                    label: 'Editar',
                    icon: 'pi pi-pencil',
                    disabled: () => !this.$checkPermission(this.$refs.grid.permission + ':editar') && this.$refs.grid.permission,
                    command: () => this.editRecord(this.$refs.grid.record)
                },
                {
                    label: 'Excluir',
                    icon: 'pi pi-trash',
                    disabled: () => !this.$checkPermission(this.$refs.grid.permission + ':excluir') && this.$refs.grid.permission,
                    command: () => this.$refs.grid.confirmDeleteRecord(this.record)
                },
                {
                    label: 'Histórico de ação',
                    icon: 'pi pi-paperclip',
                    command: () => this.logsAcoes(this.$refs.grid.record)
                }
            ]
        };
    },
    mounted() {
        this.tenantCustomers = getCurrentCustomers();
    },
    computed: {
        tenant() {
            return getCurrentCompany();
        },
        currentCustomer() {
            return getCurrentCustomers();
        }
    },
    watch: {
        tenant() {
            this.load();
        },
        currentCustomer() {
            this.tenantCustomers = getCurrentCustomers();
            this.load();
        }
    },
    created() {
        this.service = new BaseService('/feriados');
    },
    methods: {
        async load() {
            this.$refs.grid.load();
        },
        editRecord(record) {
            this.$emit('onEdit', record);
        },
        onRowSelect(record) {
            this.$emit('onEdit', record.data);
        },
        logsAcoes(data) {
            this.record = data;
            this.showLogAcoesDialog = true;
        },
        renderTipoHorario(tipo) {
            switch (tipo) {
                case 1:
                    return 'Manhã';
                case 2:
                    return 'Tarde';
                case 3:
                    return 'Ambos';
                default:
                    return 'Não Informado.';
            }
        }
    }
};
</script>
