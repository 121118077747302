<template>
    <AppPanel :location="[title]" subtitle="Preencha os campos abaixo">
        <template #content>
            <AppFormCadastro :service="service" :form="form" @onBeforeSave="onBeforeSave" @onAfterSave="onAfterSave"
                @onValidate="onValidate">
                <template #content>
                    <h5 class="mb-4">{{ title }}</h5>
                    <div class="card p-0">
                        <div class="field">
                            <label for="name">Nome</label>
                            <InputText id="nome" v-model.trim="form.nome" required="true" autofocus autocomplete="off"
                                :class="{ 'p-invalid': submitted && !form.nome }" />
                            <small class="p-error" v-if="submitted && !form.nome">Nome é obrigatória.</small>
                        </div>
                        <div class="field">
                            <label for="dataFeriado">Data do Feriado</label>
                            <Calendar id="dataFeriado" v-model="form.dataFeriado" :showIcon="true" dateFormat="dd/mm/yy"
                                selectionMode="single" :class="{ 'p-invalid': submitted && !form.dataFeriado }" />
                            <small class="p-error" v-if="submitted && !form.dataFeriado">Data do Feriado é
                                obrigatório.</small>
                        </div>
                        <div class="field">
                            <label for="periodo">Período</label>
                            <Dropdown id="periodo" v-model="form.periodo" :options="periodo" optionValue="value"
                                optionLabel="label" placeholder="Selecione o período do dia"
                                :class="{ 'p-invalid': submitted && !form.periodo }" />
                            <small class="p-error" v-if="submitted && !form.periodo">Período é obrigatório.</small>
                        </div>
                        <div class="field">
                            <label for="abrangencia">Abrangência</label>
                            <Dropdown id="abrangencia" v-model="form.abrangencia" :options="types" optionValue="value"
                                optionLabel="label" placeholder="Selecione..."
                                :class="{ 'p-invalid': submitted && !form.abrangencia }" />
                            <small class="p-error" v-if="submitted && !form.abrangencia">Abrangência é
                                obrigatório.</small>
                        </div>
                        <div v-if="form.abrangencia === 'MUNICIPAL'" class="field">
                            <label for="cidade">Cidade</label>
                            <DropDownCidade id="cidade" v-model="form.cidade" :modelValue="form.cidade"
                                :class="{ 'p-invalid': submitted && form.abrangencia === 'MUNICIPAL' && !form.cidade }" />
                            <small class="p-error"
                                v-if="submitted && form.abrangencia === 'MUNICIPAL' && !form.cidade">Cidade é
                                obrigatório.</small>
                        </div>
                    </div>
                </template>
            </AppFormCadastro>
        </template>
    </AppPanel>
</template>

<script>
import BaseService from '@/services/BaseService';
import { getCurrentCompany } from '@/services/store';
import DropDownCidade from '../../cidades/components/DropdownCidade.vue';
import moment from 'moment-timezone';

export default {
    components: { DropDownCidade },
    emits: ['onSalvar'],
    data() {
        return {
            form: {},
            submitted: false,
            types: [
                { label: 'Nacional', value: 'NACIONAL' },
                { label: 'Municipal', value: 'MUNICIPAL' }
            ],
        };
    },
    created() {
        this.service = new BaseService('/feriados');
    },
    computed: {
        title() {
            return this.form.id ? 'Editar Feriado' : 'Incluir Feriado';
        },
        getCurrentCompany() {
            return getCurrentCompany();
        },
        periodo() {
            const periodo = [
                { label: 'Manhã', value: 1 },
                { label: 'Tarde', value: 2 },
                { label: 'Ambos', value: 3 }
            ];
            return periodo;
        },
    },
    watch: {
        getCurrentCompany(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.form = {};
            }
        },
        'form.abrangencia'() {
            if (this.form.abrangencia === 'NACIONAL') {
                this.form.cidade = null;
            }
        }
    },
    methods: {
        async onBeforeSave() {
            this.submitted = true;
        },
        async onAfterSave() {
            this.submitted = false;
            this.$emit('onSalvar');
            this.form = {};
        },
        async onValidate(cbSuccess, cbError) {
            if (this.hasError()) {
                return cbError();
            }

            return cbSuccess();
        },
        hasError() {
            if (!this.form.nome) return true;
            if (!this.form.dataFeriado) return true;
            if (!this.form.abrangencia) return true;
            if (this.form.abrangencia === 'MUNICIPAL' && !this.form.cidade) return true;

        },
        async onEdit(record) {
            this.form = record;
            this.form.dataFeriado = this.form.dataFeriado ? moment.tz(this.form.dataFeriado, 'YYYY-MM-DD', 'America/Sao_Paulo').toDate() : null;
        },
    }
};
</script>

<style scoped lang="scss">
:deep(.card) {
    box-shadow: none !important;
}
</style>
