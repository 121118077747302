<template>
    <div :class="{ 'container-index': temPermissao() }" class="container">
        <div v-if="temPermissao()" class="card">
            <CadastroFeriados ref="cadastroFeriados" @onSalvar="reloadListagem"></CadastroFeriados>
        </div>
        <div class="card p-0">
            <ListagemFeriados ref="listagemFeriados" @onEdit="onEdit"></ListagemFeriados>
        </div>
    </div>
</template>
<script>
import ListagemFeriados from '../feriados/componentes/ListagemFeriados.vue';
import CadastroFeriados from '../feriados/componentes/CadastroFeriados.vue';

export default {
    components: {
        ListagemFeriados,
        CadastroFeriados
    },
    methods: {
        reloadListagem() {
            this.$refs.listagemFeriados.load();
        },
        onEdit(record) {
            this.$refs.cadastroFeriados.onEdit(record);
        },
        temPermissao() {
            return this.$checkPermission('configuracoes_feriados:criar')
        },
    }
};
</script>
<style scoped lang="scss">
:deep(.card) {
    height: 100% !important;
    box-shadow: none !important;
}

:deep(.body) {
    max-height: 67vh;
    height: 100%;
}

::-webkit-scrollbar-track {
    background-color: #F4F4F4;
}

::-webkit-scrollbar {
    width: 6px;
    background: #F4F4F4;
}

::-webkit-scrollbar-thumb {
    background: #ced4da;
    border-radius: 15px;
}

.container {
    height: calc(100vh);
    max-height: calc(100vh - 110px) !important;
}

.container-index {
    display: grid;
    gap: 10px;
    grid-template-columns: 450px auto;
    width: 100%;
    height: calc(100vh);
    max-height: calc(100vh - 110px) !important;
}

.card {
    height: 100%;
    padding: 0px;
}
</style>